import Footer from "../components/footer";
import Navbar from "../components/navbar";
import "../styles/terms.css";
import { useTranslation } from "react-i18next";
import ReactGA from 'react-ga4'

function TermsConditions() {

    ReactGA.send({hitType: "page_view", page: "/privacy", title: "Privacypage"})

    const { t } = useTranslation(['privacy']);

    return (
        <div className='App'>
            <div id="navbar">
                <Navbar />
            </div>
            <div className="terms-content">
                <h1>{ t('privacy-title') }</h1>
                <p>{ t('respect') }</p>
                <p>{ t('personal-info') }</p>
                <p>{ t('why-privacy') }</p>
                <p>{ t('no-selling') }</p>
            </div>
            <div className="footer">
                <Footer />
            </div>
        </div>
    )
}

export default TermsConditions;