import styles from '../styles/footer.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn , faInstagram, faFacebook} from '@fortawesome/free-brands-svg-icons';
import { useTranslation } from 'react-i18next';

export default function Footer() {

	const { t } = useTranslation(['home']);

    return (
        <div className={styles.footer_body}>
        	<div className={styles.footer_spacing}>
				<a 
					className={styles.fontAwesomeIcon}
					href='https://www.linkedin.com/company/versait/'
				> <FontAwesomeIcon icon={faLinkedinIn} /> </a>
				<a 
					className={styles.fontAwesomeIcon}
					href='https://www.instagram.com/versa_it/'
				> <FontAwesomeIcon icon={faInstagram} /> </a>
				<a 
					className={styles.fontAwesomeIcon}
					href='https://www.facebook.com/profile.php?id=100095687793842'
				> <FontAwesomeIcon icon={faFacebook} /> </a>
            </div>
            <div className={styles.footer_spacing}>
                <a href="/">{ t('home') }</a>
				<a href="/about">{ t('about') }</a>
				<a href="/services">{ t('services') }</a>
				<a href="/contact">{ t('contact') }</a>
            </div>
            <div className={styles.footer_spacing}>
				<p>{ t('btw_nr') }</p>
                <p>{ t('kvk_nr') }</p>
            </div>
			<div className={styles.footer_terms}>
				<div className={styles.footer_spacing}>
					<p>
						VersaIT 2023 ©&emsp;|&emsp;
						<u><a href="/Terms_and_Conditions">{ t('terms_and_conditions') }</a></u>
						&emsp;|&emsp;
						<u><a href="/Privacy_Statement">{ t('privacy_statement') }</a></u>
					</p>
				</div>
				<div className={styles.footer_google}>
					<p>
						{ t('google')}
						<u><a href='https://policies.google.com/privacy'>{ t('google_privacy')}</a></u>
						&nbsp;{ t('and')}&nbsp;
						<u><a href='https://policies.google.com/terms'>{ t('google_terms')}</a></u>
					</p>
				</div>
			</div>
        </div>
    )
}