import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import styles from '../../styles/navbar.module.css';
import dutchFlag from '../../images/netherlands-flag.svg';
import englishFlag from '../../images/united-kingdom-flag.svg';

export default function LanguageSelector() {

	const [ windowDimention, setWindowDimention ] = useState(Number);

    useEffect(() => {
        setWindowDimention(window.innerWidth);
    }, [])

    useEffect(() => {
        function handleResize() {
            setWindowDimention(window.innerWidth);
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [])

    const isMobile = windowDimention <= 768;

	type languageOption = {
		id: string;
		name: string;
		flagimg: React.JSX.Element;
	};
	
	const { i18n } = useTranslation(['navbar']);

	const handleOption = (language: string): void => {
		i18n.changeLanguage(language);
		localStorage.setItem('lng', language)

		setLangFlag(languageOptions.find((element: languageOption) => {return element.id === i18n.language;}))
		setListDisplay('none');
		setButtonVisible(true);
	};

	const languageOptions = [
	{ 
		id: "en", 
		name: "English",
		flagimg: <img src={englishFlag} height="30" width="30" alt="nope" />,
	},
	{ 
		id: "nl",
		name: "Dutch",
		flagimg: <img src={dutchFlag} height="30" width="30" alt="nope" />,
	}];

	const defaultLangFlag = languageOptions.find((element: languageOption) => {return element.id === i18n.language;});

	const [listDisplay, setListDisplay] = useState('none');
	const [buttonVisible, setButtonVisible] = useState(true);
	const [langFlag, setLangFlag] = useState(defaultLangFlag);
  
	const showDropdown = () => {
		setButtonVisible(!buttonVisible)
		if (listDisplay === 'none'){
			setListDisplay('flex');
		} else {
			setListDisplay('none');
		}
	};

	return (
		<div className={ styles.languageSelector }>
			{ buttonVisible && <button onClick={showDropdown} >{ langFlag?.flagimg }</button> }
			<ul style={{ 
				display: listDisplay, 
				flexDirection: isMobile ? "row" : "column", 
				justifyContent: "center", 
				textAlign: "center",
				margin: "0 auto", 
				lineHeight: "60px",
				overflow: "visible",
				alignSelf: "flex-start",
				zIndex: 100,
				flex: 1,
				top: 0
			}}>
				{languageOptions.map((lang) => (
					<li id={lang.id} key={lang.id} title={lang.name} style={{ 
						listStyle: "none"
					}}>
						<button onClick={() => handleOption(lang.id)}>
							{ lang.flagimg }
						</button>
					</li>
				))}
			</ul>
		</div>
	);
}