import React from 'react'
import website from './images/website.png'
import './App.css'
import Navbar from './components/navbar'
import Footer from './components/footer'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import TopLogo from "./components/logo/top_logo";
import TextLogo from "./components/logo/text_logo";

function App() {

  ReactGA.send({hitType: "page_view", page: "/home", title: "Homepage"})

  const { t } = useTranslation(['home'])

  return (
    <div className="App">
      <div id="navbar">
        <Navbar />
      </div>
      <div className="header">
        <p className="subtitle"><span className='line'> { t('subtitle_1') } </span><span className='line'> { t('subtitle_2') } </span></p>
				<div className={"logo"}>
					<TopLogo className={"App-logo"}/>
					<TextLogo className={"App-logo"}/>
				</div>
      </div>
      <div className="content">
        <div className="information">
          <div className="panel">
            <a href='/services' className='button'>
              <div className='button-content'>
                <div className="website-image">
                  <img src={ website } className='img' alt='website cartoon'/>
                </div>
                <div>
                  <p className='button-text'><b> { t('services')} </b></p>
                  <p className='button-text'> { t('information_panel_service_1') } </p>
                  <p className='button-text'> { t('information_panel_service_2') } </p>
                  <p className='button-text'> { t('information_panel_service_3') } </p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
}

export default App;
