import React from 'react'
import ReactDOM from 'react-dom/client'
import ReactGA from 'react-ga4'
import {createBrowserRouter, RouterProvider} from "react-router-dom"
import './index.css'
import App from './App'
import About from './pages/about'
import Contact from './pages/contact'
import Services from './pages/services'
import TermsConditions from './pages/termsConditions'
import Privacy from './pages/privacy'
import reportWebVitals from './reportWebVitals'
import './i18n'

const TrackingId = 'G-CMRKXMNB3E'
ReactGA.initialize(TrackingId)

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/about",
    element: <About />
  },
  {
    path: "/services",
    element: <Services />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/terms_and_conditions",
    element: <TermsConditions />,
  },
  {
    path: "/privacy_statement",
    element: <Privacy />,
  }
])

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <RouterProvider router = {router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
