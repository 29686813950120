import '../styles/contact.css'
import emailjs from '@emailjs/browser'
import React, {useState} from 'react'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'

interface EmailTemplateParams {
  [key: string]: string
}

export default function ContactForm() {
  const { t } = useTranslation(['contact']);

  const standardFormData = {
    user_name: '',
    user_email: '',
    message: '',
  }
  const [formState, setFormState] = useState(true)
  const [formData, setFormData] = useState<EmailTemplateParams>(standardFormData)

  const sendEmail = (e: { preventDefault: () => void; }) => {
    e.preventDefault();

    if (!(formData.user_name.trim() === '' || 
      formData.user_email.trim() === '' || 
      formData.message.trim() === '')) {

        setFormState(true)

        emailjs.send('service_lhlnyh3', 'template_vuwm7bg', formData, 'ps-ZZ1DMI2ezWXTZ8')
          .then((result) => {
              console.log(result.text);
              setFormData(standardFormData)
          }, (error) => {
              console.log(error.text)
          });

          ReactGA.send({hitType: "ButtonClicked", EventCategory: "SubmitEvent"})
    }
    else {
      setFormState(false)
    }
    
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <form 
      className='contact-form-content' 
      onSubmit={sendEmail}
    >
      <input 
        className='contact-input-field'
        style={{
          border: !formState ? '2px solid red' : '2px solid rgb(230,230,230)',
          backgroundColor: !formState ? 'lightcoral' : 'rgb(230,230,230)'
        }}
        type="text" 
        name="user_name" 
        value={formData.user_name} 
        onChange={handleInputChange} 
        placeholder={ t('full_name') }
      />
      <input 
        className='contact-input-field' 
        style={{
          border: !formState ? '2px solid red' : '2px solid rgb(230,230,230)',
          backgroundColor: !formState ? 'lightcoral' : 'rgb(230,230,230)'
        }}
        type="email" 
        name="user_email" 
        value={formData.user_email} 
        onChange={handleInputChange} 
        placeholder={ t('email_address') }
      />
      <textarea 
        className='contact-input-field textarea' 
        style={{
          border: !formState ? '2px solid red' : '2px solid rgb(230,230,230)',
          backgroundColor: !formState ? 'lightcoral' : 'rgb(230,230,230)'
        }}
        name="message" 
        value={formData.message} 
        onChange={handleInputChange}
        placeholder={ t('message') }
      />
      <input 
        className='submit-btn' 
        type="submit" 
        value={ t('send') }
      />
    </form>
  );
}
