import React, { useEffect, useState } from 'react';
import styles from '../styles/navbar.module.css';
import LanguageSelector from './atoms/languageSelector';
import { useTranslation } from 'react-i18next';
import TopLogo from "./logo/top_logo";
import TextLogo from "./logo/text_logo";

export default function Navbar() {

  const { t } = useTranslation(['home']);

  const [ hamburgerOpen, setHamburgerOpen ] = useState(false);
  const [ windowDimention, setWindowDimention ] = useState(Number);

  function mobileMenu() {
    setHamburgerOpen(!hamburgerOpen);
  }

  useEffect(() => {
    setWindowDimention(window.innerWidth);
  }, [])

  useEffect(() => {
    function handleResize() {
      setWindowDimention(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [])

  const isMobile = windowDimention <= 768;

  return (
    <div className={styles.content}>
      <div className={styles.versait}>
        <a href='/'>
          <TextLogo className={styles.textLogo}/>
        </a>
      </div>
      <div  className={styles.logo}>
				<TopLogo className={styles.centerLogo}/>
      </div>
      <ul className={styles.links}
          style={{
            flex: 1,
            display: isMobile ? ( hamburgerOpen ? 'inline' : 'none' ) : 'flex',
            justifyContent: "space-between",
            alignItems: "center"
          }}
      >
        <li className={styles.link}>
          <a href="/">{ t('home') }</a>
        </li>
        <li className={styles.link}>
          <a href="/about">{ t('about') }</a>
        </li>
        <li className={styles.link}>
          <a href='/services'>{ t('services') }</a>
        </li>
        <li className={styles.link}>
          <a href="/contact">{ t('contact') }</a>
        </li>
        <li className={styles.language}>
          <LanguageSelector />
        </li>
      </ul>
      <div className={styles.hamburger} onClick={mobileMenu}>
        <span className={styles.bar}></span>
        <span className={styles.bar}></span>
        <span className={styles.bar}></span>
      </div>
    </div>
  );
}