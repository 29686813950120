import i18next from 'i18next'
import {initReactI18next} from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HomeNl from './locales/nl/homepage.json'
import HomeEn from './locales/en/homepage.json'
import AboutNl from './locales/nl/aboutpage.json'
import AboutEn from './locales/en/aboutpage.json'
import ServicesNl from './locales/nl/servicespage.json'
import ServicesEn from './locales/en/servicespage.json'
import ContactNl from './locales/nl/contactpage.json'
import ContactEn from './locales/en/contactpage.json'
import TermsNl from './locales/nl/terms.json'
import TermsEn from './locales/en/terms.json'
import PrivacyNl from './locales/nl/privacy.json'
import PrivacyEn from './locales/en/privacy.json'

const resources = {
    en: {
        home: HomeEn,
        about: AboutEn,
        services: ServicesEn,
        contact: ContactEn,
        terms: TermsEn,
        privacy: PrivacyEn
    },
    nl: {
        home: HomeNl,
        about: AboutNl,
        services: ServicesNl,
        contact: ContactNl,
        terms: TermsNl,
        privacy: PrivacyNl
    }
}

const key = "lng";
i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        lng:localStorage.getItem(key) || 'nl',
        fallbackLng: 'nl',
        debug: false,
        returnObjects: true,
        interpolation: {
            escapeValue: false,
        }
    });

export default i18next;