import Navbar from '../components/navbar'
import Footer from '../components/footer'
import '../styles/contact.css'
import { useTranslation } from 'react-i18next'
import ContactForm from '../components/contact_form'
import ReactGA from 'react-ga4'

function Contact() {

  ReactGA.send({hitType: "page_view", page: "/contact", title: "Contactpage"})

  const { t } = useTranslation(['contact']);

  return (
    <div className="App">
      <div id="navbar">
        <Navbar />
      </div>
      <div className="contact-content">
        <div className="contact-text">
          <h1>{ t('title') } <br /> { t('title2') }</h1>
          <p>{ t('subtitle') }</p>
        </div>
        <div className="contact-form">
          <h1>{ t('form_title') }</h1>
          <ContactForm />
        </div>
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
}

export default Contact;
