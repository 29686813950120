import i18next from "i18next";
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import "../styles/terms.css";
import { useTranslation } from "react-i18next";
import ReactGA from 'react-ga4'

function TermsConditions() {

    ReactGA.send({hitType: "pageView", page: "/terms", title: "TermsPage"})

    const { t } = useTranslation(['terms']);

    const onButtonClick = () => {
        const pdf = i18next.language === "nl" ? "Algemene voorwaarden VersaIT.pdf" : "Terms and Conditions VersaIT.pdf"
        // using Java Script method to get PDF file
        fetch(pdf).then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob)
                // Setting various property values
                let alink = document.createElement('a')
                alink.href = fileURL
                alink.download = pdf
                alink.click()
            })
        })
        ReactGA.event({category: "User", action: "fileDownload"})
    }

    return (
        <div className='App'>
            <div id="navbar">
                <Navbar />
            </div>
            <div className="terms-content">
            
                <button onClick={onButtonClick}>
                    <FontAwesomeIcon icon={faDownload} /> &nbsp; { t('terms') }
                </button>

                <h1>{ t('terms-and-conditions') }</h1>
                <p>{ t('terms-and-conditions-applicable') }</p>
                <p>{ t('terms-and-conditions-binding') }</p>
                
                <h2>{ t('1.') }</h2>
                <p>{ t('1.1.') }</p>
                <p>{ t('1.2.') }</p>
                <p>{ t('1.3.') }</p>
                <p>{ t('1.4.') }</p>
                <p>{ t('1.5.') }</p>
                <p>{ t('1.6.') }</p>
                <p>{ t('1.7.') }</p>
                
                <h2>{ t('2.') }</h2>
                <p>{ t('2.1.') } </p>
                <p>{ t('2.2.') }</p>
                <p>{ t('2.3.') }</p>
                <p>{ t('2.4.') }</p>
                <p>{ t('2.5.') }</p>
                <p>{ t('2.6.') }</p>
                
                <h2>{ t('3.') }</h2>
                <p>{ t('3.1.') }</p>
                <p>{ t('3.2.') }</p>
                <p>{ t('3.3.') }</p>
                <p>{ t('3.4.') }</p>
                <p>{ t('3.5.') }</p>
                <p>{ t('3.6.') }</p>
                <p>{ t('3.7.') }</p>
                <p>{ t('3.8.') }</p>

                <h2>{ t('4.') }</h2>
                <p>{ t('4.1.') }</p>
                <p>{ t('4.2.') }</p>
                <p>{ t('4.3.') }</p>
                <p>{ t('4.4.') }</p>
                <p>{ t('4.5.') }</p>
                <p>{ t('4.6.') }</p>
                <p>{ t('4.7.') }</p>

                <h2>{ t('5.') }</h2>
                <p>{ t('5.1.') }</p>
                <p>{ t('5.2.') }</p>
                <p>{ t('5.3.') }</p>
                <p>{ t('5.4.') }</p>
                <p>{ t('5.5.') }</p>

                <h2>{ t('6.') }</h2>
                <p>{ t('6.1.') }</p>
                <p>{ t('6.2.') }</p>
                <p>{ t('6.3.') }</p>
                <p>{ t('6.4.') }</p>

                <h2>{ t('7.') }</h2>
                <p>{ t('7.1.') }</p>
                <p>{ t('7.2.') }</p>
                <p>{ t('7.3.') }</p>

                <h2>{ t('8.') }</h2>
                <p>{ t('8.1.') }</p>
                <p>{ t('8.2.') }</p>
                <p>{ t('8.3.') }</p>
                <p>{ t('8.4.') }</p>
                <p>{ t('8.5.') }</p>

                <h2>{ t('9.') }</h2>
                <p>{ t('9.1.') }</p>
                <p>{ t('9.2.') }</p>
                <p>{ t('9.3.') }</p>
                <p>{ t('9.3.a') }</p>
                <p>{ t('9.3.b') }</p>
                <p>{ t('9.3.c') }</p>
                <p>{ t('9.3.d') }</p>
                <p>{ t('9.4.') }</p>
                <p>{ t('9.5.') }</p>
                <p>{ t('9.6.') }</p>
                <p>{ t('9.7.') }</p>

                <h2>{ t('10.') }</h2>
                <p>{ t('10.1.') }</p>
                <p>{ t('10.2.') }</p>
                <p>{ t('10.3.') }</p>
                <p>{ t('10.4.') }</p>
                <p>{ t('10.5.') }</p>

                <h2>{ t('11.') }</h2>
                <p>{ t('11.1.') }</p>
                <p>{ t('11.2.') }</p>
                <p>{ t('11.3.') }</p>
                <p>{ t('11.4.') }</p>
                <p>{ t('11.5.') }</p>
            </div>
            <div className="footer">
                <Footer />
            </div>
        </div>
    )
}

export default TermsConditions;