import Navbar from '../components/navbar'
import Footer from '../components/footer'
import '../styles/about.css'
import { useTranslation } from 'react-i18next'
import Portret from '../images/Wessel.jpg'
import ReactGA from 'react-ga4'

function About() {

  ReactGA.send({hitType: "page_view", page: "/about", title: "Aboutpage"})

  const { t } = useTranslation(['about']);

  return (
    <div className="App">
        
      <div id="navbar">
        <Navbar />
      </div>
      <div className="about-content">
        <div className="about-info">
          <div className="about-card">
              <h2> { t('welkom') } </h2>
              <p> { t('about_me') } </p>
              <br/>
              <p> { t('my_goals') } </p>
          </div>
        </div>

        <div className="portret-card">
            <img src={Portret} alt="Wessel" />
            <div className="information">
                <p className='question'> { t('questions') } </p>
                <p><b> { t('my_name') } </b></p>
                <a href='tel:+31 6 53631863'> { t('my_cell') } </a>
                <p> { t('mail_me') } </p>
                <a href={"mailto:" + t('my_mail')}> { t('my_mail') } </a>
            </div>
        </div>
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
}

export default About;