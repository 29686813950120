import React from "react";

const TextLogo = ({className} : {className?: string}) => {
	return (
	<svg className={className} viewBox="45 500 560 184" fillRule={'evenodd'}>
		<defs>
			<linearGradient id="cls" x1="0" y1="50%" x2="100%" y2="50%" gradientUnits="userSpaceOnUse">
				<stop offset="66%" stopColor="rgb(30, 50, 130)" />
				<stop offset="78%" stopColor="rgb(60, 200, 240)"/>
				<stop offset="95%" stopColor="rgb(86,226,215)" />
			</linearGradient>
		</defs>
		<g>
			<path fill={"url(#cls)"} d="M101.58,643.5h-24L46.34,548h24.8l18.94,65.5,19.5-65.5h22.26S111.35,613.68,101.58,643.5Z"/>
			<path fill={"url(#cls)"} d="M454.08,643.5V624.6l19.5-.1v-58l-19.5.2V548h62l0,18.41-19.5.09v58.27l19.53.27,0,18.46Z"/>
			<path fill={"url(#cls)"} d="M575.56,643.5h-23v-77l-25-.16V548h72.5v18.31l-24.5.19Z"/>
			<path fill={"url(#cls)"} d="M135.08,619.5c-3.75-17.16-1.11-36.06,11.5-45.5,10-7.46,27.47-12.25,45-1,9.1,7,12.79,19.3,8,39.09l-44.16.82c.24,3.55,2.52,15.43,18.24,15.45,6.1.27,18,.18,25.38-2.86.67,4.67.91,13.22,0,14C153.05,653.72,138,631,135.08,619.5Zm33.5-36.06c5.18,0,12,3.3,12,12.06h-24C156.58,589.05,162.92,583.44,168.58,583.44Z"/>
			<path fill={"url(#cls)"} d="M219.14,643.5l-.06-77.12,21.55,0,0,6.27c8.61-7,17.31-8.64,28-4.67,7.55,3.28,12,9.33,13.5,17.5a73.13,73.13,0,0,1,1,14.44l-19.53.06c-.45-16.4-16.37-20.49-23-4.5l-.57,48Z"/>
			<path fill={"url(#cls)"} d="M296.08,640.5l.16-17.78a111.14,111.14,0,0,0,25.84,4.78c3.59,0,8.7,0,10.5-1.5,2.94-2.61,3-6.37-1-8-9.32-3.46-18.69-7-28-11-14-6.17-10-27.84,1-34,8.1-5,16.76-6.6,26.5-6.5a97.05,97.05,0,0,1,21,2.8v18.3c-9.15-4.21-21-6.07-28.52-3.6-5,2.26-5.68,7.27-1,9,8.38,3.27,16.92,6,25,9,8.68,4.39,12,14,9.5,24.5C354.76,635.29,341.68,650.86,296.08,640.5Z"/>
			<path fill={"url(#cls)"} d="M418.06,643.5l0-6c-20.8,10.22-35.76,6.63-40.94,1.23-5.64-6.64-9.82-19.78-1.06-31.23a30.66,30.66,0,0,1,18.5-10.5,93.54,93.54,0,0,1,21-1.5q.63-12.36-12-12c-8.28.36-18.22,3.47-25.61,7.11l.11-18.11a41.6,41.6,0,0,1,16.5-5.5c9.48-1.06,19-1.12,28,2,7.73,3,14.07,8.38,14.5,16.5v58Zm-17.48-31c4.63-1.75,13-.78,14.93.32.37,10.35-4.57,12.66-7.76,13.63-4.49,1-9.59,1.48-11.17-.45C393.49,622.09,395.56,614.78,400.58,612.5Z"/>
		</g>
	</svg>
	);
}

export default TextLogo;