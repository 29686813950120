import React from "react";

const TopLogo = ({className}: { className?: string }) => {
	return (
		<svg className={className} viewBox="0 -10 609.49 600" fillRule={'evenodd'}>
			<defs>
				<linearGradient id="logo" x1="30%" y1="80%" x2="100%" y2="0" gradientUnits="userSpaceOnUse">
					<stop offset="12%" stopColor="rgb(30, 50, 130)"/>
					<stop offset="50%" stopColor="rgb(60, 200, 240)"/>
					<stop offset="90%" stopColor="rgb(64, 224, 208)"/>
				</linearGradient>
				<linearGradient id="logotip" x1="40%" y1="10%" x2="100%" y2="40%" gradientUnits="userSpaceOnUse">
					<stop offset="55%" stopColor="rgb(30, 50, 130)"/>
					<stop offset="100%" stopColor="rgb(60, 120, 180)"/>
				</linearGradient>
				<linearGradient id="logomiddle" x1="0" y1="0" x2="100%" y2="100%" gradientUnits="userSpaceOnUse">
					<stop offset="30%" stopColor="rgb(30, 50, 130)"/>
					<stop offset="70%" stopColor="rgb(60, 200, 240)"/>
				</linearGradient>
			</defs>
			<g>
				<path fill={"url(#logo)"}
							d="M89,0H274c57.18,0,87.48,56,93.5,65,27.72,39.39,80.05,117,87.32,126.39-18.11,40.08-58.14,61.16-85.82,56.11Q330.45,192.66,294.5,136c-12.13-16.15-21-34.55-48.5-35.5q-56-1-112,0c-9.45,0-24.61,11.61-23,29,2,24.29,48.61,86.34,69.5,108.5,13,14.79,23.39,20.47,38.5,25.5,21.33,5.63,33.45,6.34,62.5,5.5q51.75.72,103.5-1.5c28-.5,59.29-18.87,86.29-61.61,14.95-23.89,17.82-32.78,32.45-62.52,11.89-23.15-.4-41.21-24.74-42.87l-49.08-.16c-54.83-80.56-67.1-89.72-81-100.34H516c48,0,93.49,43.07,93.49,95.64,0,31.86-11.49,64.07-22.86,90.44C561.53,240,523.58,286.61,480,328.5q-40.46,34.33-94,35-78.49,1-157,0Q149.27,360.76,98.5,300c-26.89-36.45-51.37-67.39-71.42-105C18.15,175.9,0,145.29,0,96,0,52.58,36.29,0,89,0Z"/>
				<path fill={"url(#logomiddle)"}
							d="M305.08,189.43c-.5,1.07-38.82,61.82-40.32,62.65-32.21,1.42-49.5-2.61-89.18-49.58,16.08-25.83,34.46-56.47,50-82.64C263.66,119.22,276.61,139.82,305.08,189.43Z"/>
				<path fill={"url(#logotip)"}
							d="M468.36,367.81c-23,41.8-49.91,83.22-82.28,129.69-31.47,42.34-100,47.39-140.5,3.5-12.87-16.11-42.72-55.41-94-131.06,51.3,22.74,128.72,19.5,148.52,19.56a311.52,311.52,0,0,0,18.5,31q10.19-16.12,20-32.5C377.27,387.28,429.25,389.45,468.36,367.81Z"/>
			</g>
		</svg>
	)
}


export default TopLogo;