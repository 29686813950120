import Navbar from '../components/navbar'
import Footer from '../components/footer'
import '../styles/services.css'
import { useTranslation } from 'react-i18next'
import AndroidLogo from '../images/Android.png'
import WebsiteImg from '../images/website.png'
import UnderConstructionImg from '../images/under construction.png'
import DataAnalytics from '../images/data analytics.png'
import ReactGA from 'react-ga4'

function Services() {

    ReactGA.send({hitType: "page_view", page: "/services", title: "Servicespage"})

    const { t } = useTranslation(['services']);

    return (
        <div className='App'>
            <div id="navbar">
                <Navbar />
            </div>
            <div className="services-content">
                <div className="title">
                    <h1> { t('services') } </h1>
                </div>
                <div className="cards">
                    <div className="card">
                        <img className="image" src={AndroidLogo} alt="Android" /> {/* "The Android robot is reproduced or modified from work created and shared by Google and used according to terms described in the Creative Commons 3.0 Attribution License." */}
                        
                        <div className="text">
                            <p> <b>{ t('android_title') } </b> </p>
                            <p> { t('android_content') }</p>
                        </div>
                    </div>

                    <div className="card">
                        <img className="image" src={DataAnalytics} alt="Data Analytics" />
                        
                        <div className="text">
                            <p> <b>{ t('data_title') } </b> </p>
                            <p> { t('data_content') }</p>
                        </div>
                    </div>

                    <div className="card">
                        <img className="image" src={WebsiteImg} alt="Website" />
                        
                        <div className="text">
                            <p> <b> { t('web_title') } </b> </p>
                            <p> { t('web_content') }</p>
                        </div>
                    </div>

                    <div className="card">
                        <img className="image" src={UnderConstructionImg} alt="Features" />
                        
                        <div className="text">
                            <p> <b>{ t('feature_title') } </b> </p>
                            <p> { t('feature_content') }</p>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div className="footer">
                <Footer />
            </div>
        </div>
    );
}

export default Services;